import { Home } from 'components/Home';
import { HomePageProps } from 'common/types/home';
import { OffersPages } from 'common/types/pages';
import { prefetchHomeData } from 'common/getServerSideProps';
import { Offer } from 'generated/types';

const SolutionsPage = ({ prefetch }: HomePageProps<Offer>) => {
  return <Home page={OffersPages.solutions} prefetch={prefetch} />;
};

export const getServerSideProps = prefetchHomeData(OffersPages.solutions);

export default SolutionsPage;